import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Spin, Select } from 'antd';
import { Form } from 'react-bootstrap';
import "./style.css"
import buildReq, { checkResponse } from '../../request_builder';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { AiOutlinePlusCircle, AiOutlineCloseCircle } from 'react-icons/ai';

const { Option } = Select;

export default function StartAssessments(props) {

    const [infra, setInfra] = useState([]);
    const [loading, setLoading] = useState(false);
    const [project_name, setProject] = useState("");
    const history = useHistory();
    let should_run_auto = false;
    
    useEffect(() => {
        async function init(){
            try{
                setLoading(true);
                let res = await buildReq("/infrastructure")
                console.log(res.data);
                let res2 = await buildReq('/resource');
                setInfra(res.data.map(e=>{
                    let ind = res2.data.findIndex(r => r._id === e.infrastructure_name);
                    let obj = [];
                    if(ind !== -1){
                        obj = res2.data[ind].resources.map(e => {
                            e.info = e.info.length > 0 ? [e.info] : [];
                            e.selected = true;
                            return e;
                        });
                    }
                    console.log(obj);
                    return ({
                        ...e,
                        tags : e.tags,
                        _tags: e.tags,
                        selected : true,
                        resources: obj 
                    })
                }));
            } catch(err){
                checkResponse(err);
            } finally{
                setLoading(false);
            }
        }
        init();
    }, []);

    function onClick(ev) {
        ev.preventDefault();

        console.log(infra);

        if(project_name.length === 0){
            toast.error("Project Name is required");
            return false;
        }

        let infrastructure = infra.filter(e=>e.selected);

        if(infrastructure.length === 0){
            toast.error("Please select at least 1 infrastructure provider");
            return false;    
        }

        if(infrastructure.some(e=>e.tags.length === 0)){
            toast.error("Please select infrastructure(s) used for the selected infrastructure providers.");
            return false;        
        }

        if(infrastructure.some(e=>e.credentials?.length > 0 ? e.credentials.some(c=>!c.value) : false)){
            toast.error("Credentials are required to test Automated controls");
            return false;        
        }

        if(infrastructure.some(e=> e.resources?.length > 0 ? 
            e.resources.some(c=> c.selected && c.info.some(f => f.some(d=> !d.value))) 
            : 
            false
        )){
            toast.error("Please fill all resource details for Automated controls");
            return false;        
        }

        infrastructure.forEach( e=> {
            e.resources = e.resources.filter(e => e.selected);
        });

        buildReq("/assessment", 2, { project_name, infrastructure})
            .then((res) => {
                setLoading(false);
                if(should_run_auto)
                    history.push({pathname : `/auto_test/${res.data._id}`});
                else
                    history.push({pathname : `/assessment/${res.data._id}`});
            })
            .catch(checkResponse)
            .finally(()=>setLoading(false))

        return false;
    }

    function startAndRunAuto(){
        should_run_auto = true;
        onClick();
    }

    function start(){
        should_run_auto = false;
        onClick();
    }

    function onInfraChange(index, ev) {
        let checked = ev.target.checked;
        let arr = JSON.parse(JSON.stringify(infra))
        arr[index].selected = checked;
        setInfra(arr);
    }

    function onTagsChange(infra_ind, tags) {
        let arr = JSON.parse(JSON.stringify(infra));
        arr[infra_ind].tags = tags;
        arr[infra_ind].resources.forEach(e => {
            if(tags.includes(e.tag)){
                e.selected = true;
            } else e.selected = false;
        });
        console.log(arr);
        setInfra(arr);
    }

    const onCredChange = (infra_ind, crd_ind, val) => { 
        setInfra((st)=>{
            let arr = JSON.parse(JSON.stringify(st));
            arr[infra_ind].credentials[crd_ind].value = val;
            return arr; 
        })
    }

    const onDataChange = (inf_ind, r_ind, f_ind, v_ind, val) => {
        let obj = JSON.parse(JSON.stringify(infra));
        obj[inf_ind].resources[r_ind].info[f_ind][v_ind].value = val;
        setInfra(obj);
        console.log(infra);
    }

    const addField = (inf_ind, r_ind) => {
        let obj = JSON.parse(JSON.stringify(infra));
        let res = obj[inf_ind].resources[r_ind];
        let data = JSON.parse(JSON.stringify(res.info[res.info.length - 1]));
        obj[inf_ind].resources[r_ind].info.push(data.map(e=>{
            e.value = ""
            return e;
        }));
        setInfra(obj);
        console.log(infra);
    }

    const removeField = (inf_ind, r_ind, f_ind) => {
        let obj = JSON.parse(JSON.stringify(infra));
        obj[inf_ind].resources[r_ind].info.splice(f_ind, 1);
        setInfra(obj);
        console.log(infra);
    }

    return (
        <Spin className="mt-2 mb-5" spinning={loading}>
            <Form className="mb-5">

                <Form.Group controlId="email">
                    <h6>Project Name</h6>
                    <Form.Control type="text" placeholder="Enter Project Name" 
                        onChange={(ev) => setProject(ev.target.value)} />
                </Form.Group>

                <h6>Select Infrastructure Provider</h6>

                {
                    infra.map((e, i) =>
                        <div className="bg-light p-3 mb-4" key={i}>
                            <Checkbox checked={e.selected}
                                onChange={(ev)=>onInfraChange(i, ev)}
                                className="mb-2">
                                <span>
                                    { e.infrastructure_name }
                                </span>
                            </Checkbox>
                            {
                                e.selected && 
                                <div>
                                    <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="Select Infrastructure Used"
                                        onChange={(val)=>onTagsChange(i, val)}
                                        optionLabelProp="label"
                                        defaultValue={e._tags}
                                    >
                                        {
                                            e._tags.map((tag, j) =>
                                                <Option key={j} value={tag} label={tag}>
                                                    {tag}
                                                </Option>
                                            )
                                        }
                                    </Select>
                                </div>
                            }
                        </div>
                    )
                }
                
                {
                    infra.map((e, i) =>
                    <React.Fragment key={i}>
                        {
                            e.selected && e.credentials && e.credentials.length > 0 && 
                            <React.Fragment>
                                <h6 className="mt-4">{e.infrastructure_name} Access Credentials</h6>
                                {
                                    e.credentials.map((cr,cr_i)=>
                                        <Form.Group key={cr_i}>
                                            <Form.Label>{cr.placeholder}</Form.Label>
                                            <Form.Control type="text" placeholder={cr.placeholder} 
                                                onChange={(ev)=>onCredChange(i, cr_i, ev.target.value)}
                                            />
                                        </Form.Group>
                                    )
                                }
                            </React.Fragment> 
                        } 
                    </React.Fragment>
                    )
                }

                {
                    infra.map((e, i) =>
                    <React.Fragment key={i}>
                        {
                            e.selected && e.resources && e.resources.some(e => e.selected) &&
                            <React.Fragment>
                                <h6 className="mt-4">{e.infrastructure_name} Resource Details</h6>
                                {
                                    e.resources.map((r, r_i)=> r.selected && r.info.length > 0 && 
                                        <div key={r_i} className="border border-primary rounded p-2 my-2">
                                            <div className="my-3">
                                                <strong>{r.tag}</strong>
                                                <Button icon={<AiOutlinePlusCircle className="mr-2"/>} 
                                                    type="primary" size="small" className="ml-3"
                                                    onClick={()=>addField(i, r_i)}
                                                >
                                                    Add Another
                                                </Button>
                                            </div>
                                            <Form.Group>
                                                {
                                                    r.info.map((f, f_i) =>
                                                        <div key={f_i} className='mt-2 pb-2 border-bottom border-light'>
                                                            <div className='mb-1'>
                                                                <AiOutlineCloseCircle size="25px"
                                                                    onClick={()=>removeField(i, r_i, f_i)} 
                                                                />
                                                            </div>
                                                            {
                                                                f.map((val, v_i) =>
                                                                    <React.Fragment key={v_i} > 
                                                                        <Form.Control type="text" className="mb-1" placeholder={val.placeholder} value={val.value || ''}
                                                                            onChange={(ev)=>onDataChange(i, r_i, f_i, v_i, ev.target.value)}
                                                                        />
                                                                    </React.Fragment>
                                                                )
                                                            }
                                                        </div>   
                                                    )
                                                }
                                            </Form.Group>
                                        </div>
                                    )
                                }
                            </React.Fragment> 
                        } 
                    </React.Fragment>
                    )
                }

                <Button type="primary" htmlType="button" loading={loading} className="mt-4" onClick={startAndRunAuto}>
                    Start Assessment And Run Auto Test 
                </Button>
                <Button type="primary" htmlType="button" loading={loading} className="mt-4" onClick={start}>
                    Start Assessment
                </Button>

            </Form>
        </Spin>
    )

}