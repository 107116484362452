import React, {useState} from 'react';
import { Card, Form } from 'react-bootstrap';
import { Button } from 'antd';
import 'antd/dist/antd.css'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify'; 
import buildReq from '../../request_builder';

export default function ForgotPass({login, ...props}){
    
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);    

    const onClick = async (event)=>{
        event.preventDefault();

        if(email.length === 0){
            toast.error("Please enter a User Id")
            return;
        }

        try{
            setError("");
            setSuccess("");
            setLoading(true)
            let res = await buildReq(`/users/forgot_password?user_id=${email}`);
            if(res.status === 200){
                res = res.data;
                setSuccess("Instructions to recover you account have been sent to your registered email. Please use the instructions to recover your account.");                
            }
        }
        catch(err){
            console.log(err);
            if(err.response)
                setError(err.response.data);
            else setError("Some error occured, please try again")
        }
        finally{
            setLoading(false)
        }
    }

    return(
        <div className="h-100 w-100 container d-flex align-items-center">
            <div className="row my-auto w-100">
                <div  className="col-12 col-md-8 offset-md-2">
                    <Card>
                        <Card.Header>
                            <h5 className="h-5 mb-0">Recover Account</h5>
                        </Card.Header>
                        <Card.Body>
                            <Form className="mb-3" onSubmit={onClick}>

                                <p className="text-success">{success}</p>
                                <p className="text-danger">{error}</p>

                                <Form.Group controlId="email">
                                    <Form.Label>Please enter your User Id to recover your account</Form.Label>
                                    <Form.Control type="email" placeholder="Enter User Id" onChange={(ev)=>setEmail(ev.target.value)}/>
                                </Form.Group>

                                <Button type="primary" htmlType="submit"  onClick={onClick} loading={loading}> 
                                    Recover Account
                                </Button>

                            </Form>   

                            <Link className="text-decoration-none d-block mb-1" to="/login">Sign In</Link>

                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    )

}