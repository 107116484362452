import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { connect } from 'react-redux'
import { useCookies } from 'react-cookie';

import Header from './components/header';
import Login from './components/login'
import Profile from './components/profile'
import { loginAction, logoutAction } from './actions/auth';
import ForgotPass from "./components/forgotPass";
import Reset from "./components/resetPass";
import Home from "./components/home";
import AllAssessments from "./components/allAssessments";
import Assessment from "./components/assessment";
import Report from "./components/report";
import EditAssessment from "./components/editAssessment";
import AutoTest from "./components/allAssessments/loader";

function WithAuth({Component,token, ...props}){
	return(
			<Route {...props}
				render={({location})=>(
					token ? (
						<Component />
					)
					: 	
					<Redirect to={{pathname : "/login", state : {from : location}}}/>
				)}
			/>
	)
}

function WithoutAuth({Component,token, ...props}){
	return(
			<Route {...props}
				render={({location})=>(
					token ? 
					<Redirect to={{pathname : "/", state : {from : location}}}/>
					:
					<Component />
				)}
			/>
	)
}

function App({login, logout, identify, ...props}) {

	const [cookie] = useCookies(['auth']);
	const [authenticated, setAuthenticated] = useState(cookie.auth?.token);

    const [toggle, setToggle] = useState(false);
    const [active, setActive] = useState("HOME");
	
	useEffect(()=>{
		if(cookie.auth && cookie.auth.token) 
			login(cookie.auth);
	})

	useEffect(()=>{
		setAuthenticated(cookie.auth?.token)
		if(cookie.auth?.token){
			login(cookie.auth);
		}
		else{
			logout();

		}

	}, [cookie, login, logout])

	return (
		<Router>
			<div className="h-100 w-100 d-flex flex-column">
				
				<Header  {...{active, toggle, setActive, setToggle}}/>
				<div className="flex-fill d-flex" id="body">
					<Switch>

						<WithoutAuth path="/login" exact Component={Login} token={authenticated}/>
						<WithoutAuth path="/reset_pass/:token" exact Component={Reset} token={authenticated}/>
						<WithoutAuth path="/forgot_pass" exact Component={ForgotPass} token={authenticated}/>
						<WithAuth path="/home" exact Component={AllAssessments} token={authenticated}/>
						<WithAuth path="/assessment/:id" exact Component={Assessment} token={authenticated}/>
						<WithAuth path="/edit/:id" exact Component={EditAssessment} token={authenticated}/>
						<WithAuth path="/report/:id" exact Component={Report} token={authenticated}/>
						<WithAuth path="/profile" exact Component={Profile} token={authenticated}/>
						<WithAuth path="/auto_test/:id" exact Component={AutoTest} token={authenticated}/>
						<Route path="/">
							<Home  {...{active, toggle}}/>
						</Route>

					</Switch>
				</div>
			</div>
		</Router>
	);
}

const mapStateToProps = (st)=>({token : st.auth.token});
const mapDispatchToProps = (dispatch)=>({
	login : (data) => dispatch(loginAction(data)), 
	logout : ()=>dispatch(logoutAction())
});
export default connect(mapStateToProps, mapDispatchToProps)(App);