import React from 'react';
import 'antd/dist/antd.css'
import "./style.css"
import { IoIosAnalytics, MdPeopleOutline, IoIosGlobe, IoMdBook, MdLockOutline, IoIosCloudOutline } from 'react-icons/all';
import { FaLinkedin, FaTwitter } from 'react-icons/fa'
import { connect } from 'react-redux';


function Home({ active, toggle }) {

    return (
        <div className={`w-100 h-100 ${toggle ? "mobile-nav-active" : ""}`}>

            {/* Intro Section */}

            <section id="intro" className="clearfix">
                <div className="container">

                    <div className="intro-img">
                        <img src="/banner.jpg" alt="" className="img-fluid" />
                    </div>

                    <div className="intro-info">
                        <h1 className="display-4">
                            We Assess <br />Cyber Risk<br />
                            <span>For Your Business!</span>
                        </h1>
                    </div>

                </div>

            </section>

            <section>
                <header className="my-4">&emsp;</header>
            </section>

            <main id="main">

                <section id="about">
                    <div className="container">

                        <header className="section-header">
                            <h3>About Us</h3>
                            <p>Reduce your cyber risk across your environments(Legacy,Cloud,Hybrid) with the help of our Governance Risk and Compliance (GRC) platform.</p>
                        </header>

                        <div className="row about-container my-5">
                            <iframe src="https://www.youtube.com/embed/I7ibeLIxBAs" title="CyberRMP"
                                className="w-100" height="350px">
                            </iframe>
                        </div>

                    </div>
                </section>


                <section id="services" className="section-bg">
                    <div className="container">

                        <header className="section-header">
                            <h3>Services</h3>
                        </header>

                        <div className="row">

                            <div className="col-md-6 col-lg-5 offset-lg-1 wow bounceInUp" data-wow-duration="1.4s">
                                <div className="box">
                                    <div className="icon">
                                        <IoIosAnalytics style={{color: "#ff689b"}} size={65}/>
                                    </div>
                                    <h4 className="title">
                                        Legacy environments
                                    </h4>
                                    <p className="description">
                                        Connect our GRC tool to your data sources using our connectors and assess cyber risk score and remediate security issues automatically across all your projects.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-5 wow bounceInUp" data-wow-duration="1.4s">
                                <div className="box">
                                    <div className="icon">
                                        <IoIosCloudOutline style={{color: "#e9bf06"}} size={65} />
                                    </div>
                                    <h4 className="title">
                                        Cloud Security
                                    </h4>
                                    <p className="description">
                                        Our solution gives a holistic view of your cloud environment (AWS,Azure,GCP) and provides  continuous monitoring and vulnerability management.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-5 offset-lg-1 wow bounceInUp" data-wow-delay="0.1s" data-wow-duration="1.4s">
                                <div className="box">
                                    <div className="icon">
                                        <MdLockOutline style={{color: "#3fcdc7"}} size={65}/>
                                    </div>
                                    <h4 className="title">
                                        Application Security
                                    </h4>
                                    <p className="description">
                                        We help you with building continuous security assurance in your CI-CD pipeline and also inform you about open source vulnerabilities.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-5 wow bounceInUp" data-wow-delay="0.1s" data-wow-duration="1.4s">

                                <div className="box">
                                    <div className="icon">
                                        <IoMdBook style={{color: "#41cf2e"}} size={65}/>
                                    </div>
                                    <h4 className="title">
                                        Training
                                    </h4>
                                    <p className="description">
                                        Improve your employees security capabilities using our training in GRC and cloud security areas. Please visit<br />
                                        <a href="https://proper-devsecops.com/">https://proper-devsecops.com</a>
                                    </p>
                                </div>

                            </div>

                            <div className="col-md-6 col-lg-5 offset-lg-1 wow bounceInUp" data-wow-delay="0.2s" data-wow-duration="1.4s">
                                <div className="box">
                                    <div className="icon">
                                        <IoIosGlobe style={{color: "#d6ff22"}} size={65} />
                                    </div>
                                    <h4 className="title">
                                        Regulatory Compliance(GDPR,PCI)
                                    </h4>
                                    <p className="description">
                                        We help with GDPR compliance for your projects which collect PII. Automate your PCI compliance using our PCI Checker tool.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-5 wow bounceInUp" data-wow-delay="0.2s" data-wow-duration="1.4s">
                                <div className="box">
                                    <div className="icon">
                                        <MdPeopleOutline style={{color: "#4680ff"}} size={65}/>
                                    </div>
                                    <h4 className="title">
                                        Third party Vendors
                                    </h4>
                                    <p className="description">
                                        Vendor risks are important to business. We help with assessing vendors and inform you about high risk vendors using our dashboard capabilities.
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>

            </main>

            {/* Footer */}

            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-4 col-md-6 offset-lg-1 footer-info">
                                <h3>CyberRMP</h3>
                                <p>
                                Reduce your cyber risk across your environments(Legacy,Cloud,Hybrid) with the help of our Governance Risk and Compliance (GRC) platform.
                            </p>
                            </div>

                            <div className="col-lg-4 offset-lg-3 offset-md-6 col-md-6 footer-contact">
                                <h4>Contact Us</h4>
                                <p>
                                    Taskial Solutions <br/>
                                    7 Bell Yard, London,<br/>
                                    England, WC2A 2JR<br/>
                                    <strong>Phone:</strong> +447438295181<br/>
                                    <strong>Email :</strong> info@cyberrmp.com<br/>
                                </p>

                                <div className="social-links">
                                    <a href="https://twitter.com/CyberRmp" className="linkedin">
                                        <FaTwitter />
                                    </a>
                                    <a href="https://www.linkedin.com/company/cyberrmp/" className="linkedin">
                                        <FaLinkedin />
                                    </a>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="copyright">
                        &copy; Copyright <strong>Taskial Solutions</strong>. All Rights Reserved
                </div>
                </div>
            </footer>

        </div>
    )

}

const mapStateToProps = (st) => ({ auth: st.auth })
export default connect(mapStateToProps, null)(Home);