import React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import store from './store';
import Router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CookiesProvider } from 'react-cookie';
import { ToastContainer } from 'react-toastify';

function App() {
	return (
		<CookiesProvider>
			<Provider store={store}>
				<Router />
				<ToastContainer
					position="top-center"
					autoClose={3000}
					hideProgressBar={true}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					bodyClassName="font"
					pauseOnFocusLoss={false}
				/>
			</Provider>
		</CookiesProvider>
	);
}

export default App;