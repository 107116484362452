import React, {useState} from 'react';
import { Card, Form } from 'react-bootstrap';
import { Button } from 'antd';
import 'antd/dist/antd.css'
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify'; 
import buildReq from '../../request_builder';

export default function Reset({login, ...props}){
    
    const [pass, setPass] = useState("");
    const [confirm, setConfirm] = useState("");
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);    
    const { token, verify } = useParams();

    const onClick = async (event)=>{
        event.preventDefault();

        if(pass.length < 8){
            toast.error("Password should be atleast 8 character long")
            return;
        }

        if(pass !== confirm){
            setError("password and confirm password do not match")
            return;
        }
        
        try{
            setError("");
            setSuccess("");
            setLoading(true)
            let res = await buildReq('/users/reset_pass', 2, {
                password : pass,
                verify_active : verify === 'true',
                token : token
            })
            if(res.status === 200){
                res = res.data;
                setSuccess("A email with password reset instructions has been sent to your email. Please use the insturctions to recover your account ");                
            }
        }
        catch(err){
            console.log(err);
            if(err.response)
                setError(err.response.data);
            else setError("Some error occured, please try again")
        }
        finally{
            setLoading(false)
        }
    }

    return(
        <div className="h-100 w-100 container d-flex align-items-center">
            <div className="row my-auto w-100">
                <div  className="col-12 col-md-8 offset-md-2">
                    <Card>
                        <Card.Header>
                            <h5 className="h-5 mb-0">Recover Account</h5>
                        </Card.Header>
                        <Card.Body>
                            {
                                success.length > 0 ?
                                <p>
                                    Password chnaged successfully, please 
                                    <Link className="text-decoration-none d-block mb-1" to="/login">Sign In</Link>
                                    to continue
                                </p>
                                :
                                <Form className="mb-3" onSubmit={onClick}>

                                    <p className="text-danger">{error}</p>

                                    <Form.Group controlId="pass">
                                        <Form.Label>Enter New Password</Form.Label>
                                        <Form.Control type="password" placeholder="Enter New Password" onChange={(ev)=>setPass(ev.target.value)}/>
                                    </Form.Group>

                                    <Form.Group controlId="new_pass">
                                        <Form.Label>Renter New Password</Form.Label>
                                        <Form.Control type="password" placeholder="Renter New Password" onChange={(ev)=>setConfirm(ev.target.value)}/>
                                    </Form.Group>

                                    <Button type="primary" htmlType="submit"  onClick={onClick} loading={loading}> 
                                        Change Password
                                    </Button>

                                </Form>  
                            }

                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    )

}
