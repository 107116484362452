import React from 'react';
import { Tabs } from 'antd';
import "./style.css"
import AssessmentTable from './table';
import StartAssessments from './start';

const { TabPane } = Tabs;

export default function AllAssessments(){
    
    return(
        <div className="container mt-5">
            <Tabs>
                <TabPane tab="Start Assessment" key="1">    
                    <StartAssessments />                
                </TabPane>

                <TabPane tab="Onprogress Assessments" key="2">
                    <AssessmentTable status="ONPROGRESS" />                    
                </TabPane>
                
                <TabPane tab="Submitted Assessments" key="3">
                    <AssessmentTable status="SUBMITTED" />                   
                </TabPane>
                
                <TabPane tab="Reviewed Assessments" key="4">
                    <AssessmentTable status="REVIEWED" />     
                </TabPane>
            </Tabs>
        </div>
    )

}