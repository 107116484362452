import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import buildReq, { checkResponse } from '../../request_builder';
import { toast } from 'react-toastify'
import { Card, Form } from 'react-bootstrap';
import { Button } from 'antd';

function Profile(props){

    const [name, setName] = useState("");
    const [confirm, setConfirm] = useState("");
    const [pass, setPass] = useState("");
    const [old, setOld] = useState("");
    const [passLoading, setPassLoading] = useState(false);
    const [nameLoading, setNameLoading] = useState(false);
    const dispatch = useDispatch();

    async function onClick(ev){

        if(name.length < 3){
            toast.error("Name should be at least 3 character long")
            return;
        }
        try{
            setNameLoading(true)
            let res = await buildReq(`/users/change_name?name=${name}`, 3);
            if(res.status === 201){
                toast.success("Name changed successfully, please logout and login again.");
                dispatch({
                    type : "UPDATE_USER",
                    payload : {
                        name : name
                    }
                });
            }
        }
        catch(err){
            checkResponse(err);
            toast.error("Some error occurred please try again later");
        }
        finally{
            setNameLoading(false);
        }
    }
    
    async function onClick2(ev){

        if(pass.length <8){
            toast.error("Password should be at least 8 character long.");
            return;
        }

        if(pass !== confirm){
            toast.error("Password and confirm password do not match.");
            return;
        }

        if(pass === old){
            toast.error("New password cannot be same as old password.");
            return;
        }

        try{
            setPassLoading(true)
            let res = await buildReq('/users/change_pass', 2, {_new : pass, old : old})
            if(res.status === 201)
                toast.success("Password updated successfully");
        }
        catch(err){
            checkResponse(err);
            toast.error("Some error occurred please try again later");
        }
        finally{
            setPassLoading(false);
        }
    }

    return(
        <div className="h-100 w-100 d-flex justify-content-center align-items-center">

            <Card className="mb-5 w-75">

                <Card.Header>
                    <h5 className="h-5 mb-0">Update Name</h5>
                </Card.Header>
                <Card.Body>
                    <Form className="mb-3">

                        <Form.Group controlId="name">
                            <Form.Label>Enter Full Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter Full Name" onChange={(ev)=>setName(ev.target.value)} />
                        </Form.Group>

                        <Button type="primary" onClick={()=>onClick()} loading={nameLoading}> 
                            Update Name
                        </Button>

                    </Form>   

                </Card.Body>

                <Card.Header>
                    <h5 className="h-5 mb-0">Change Password</h5>
                </Card.Header>
                <Card.Body>
                    <Form className="mb-3">

                        <Form.Group controlId="old_pass">
                            <Form.Label>Old Password</Form.Label>
                            <Form.Control type="password" placeholder="Enter Old Password" onChange={(ev)=>setOld(ev.target.value)}/>
                        </Form.Group>

                        <Form.Group controlId="password">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control type="password" placeholder="Enter New Password" onChange={(ev)=>setPass(ev.target.value)}/>
                        </Form.Group>

                        <Form.Group controlId="new_pass">
                            <Form.Label>Renter New Password</Form.Label>
                            <Form.Control type="password" placeholder="Renter New Password" onChange={(ev)=>setConfirm(ev.target.value)}/>
                        </Form.Group>


                        <Button type="primary" onClick={()=>onClick2()} loading={passLoading}> 
                            Change Password
                        </Button>

                    </Form>   
                </Card.Body>

            </Card>

        </div>
    )

}

const mapStateToProps = (st)=>({token : st.auth.token})
export default connect(mapStateToProps)(Profile);