import React, { useState, useEffect, useReducer } from 'react';
import { Layout, Menu , Button, Spin } from 'antd';
import "./style.css"
import buildReq, { checkResponse } from '../../request_builder';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Control from './control';
import { Cookies } from 'react-cookie';

const { Header, Content, Sider } = Layout;

const initialState = [];

function reducer(state, action) {

    const { infra_index, control_type_index, control_index, field_index, value, result, error } = action.data;    
    let arr = JSON.parse(JSON.stringify(state));
    let scored=0;
    let control_type, control;

    switch (action.type) {
        
        case 'initialize':
            arr = JSON.parse(JSON.stringify(action.data));
            arr.forEach(e=>
                e.result.forEach(ct=>{
                    scored=0;
                    ct.controls.forEach(e=>{
                        if(e.test_result) scored++;
                    })
                    ct.percentage = Math.floor((scored/ct.controls.length)*100);
                })
            );
            return arr;

        case 'comment':
            arr[infra_index].result[control_type_index].controls[control_index].comment = value;
            return arr;
        
        case 'field':
            let field = arr[infra_index].result[control_type_index].controls[control_index].connector.fields[field_index]; 
            if(field.multiple)
                field.value = value;
            else
                field.value = [value];
            return arr;
        
        case 'change_control_status':
            control_type = arr[infra_index].result[control_type_index];
            control = arr[infra_index].result[control_type_index].controls[control_index];
            control.test_result = value;
            if(value === 'not applicable'){
                control.connector.fields.forEach(e=>!e.cred ? e.value = [] : null);
                control.failed_for = [];
            }
            control_type.controls.forEach(c => {
                if(c.test_result) scored+=1;
            });
            control_type.percentage = Math.floor((scored/control_type.controls.length)*100);

            return arr;
        
        case 'run_test':
            arr[infra_index].result[control_type_index].controls[control_index].loading = true;
            return arr;

        case 'run_test_success':
            control_type = arr[infra_index].result[control_type_index];
            control = arr[infra_index].result[control_type_index].controls[control_index];
            
            control.loading = false;
            control.test_result = result.result;
            control.failed_for = result.failed_for;
            control.error = "";

            control_type.controls.forEach(c => {
                if(c.test_result) scored+=1;
            });
            control_type.percentage = Math.floor((scored/control_type.controls.length)*100);

            return arr;

        case 'run_test_error':
            arr[infra_index].result[control_type_index].controls[control_index].loading = false;
            arr[infra_index].result[control_type_index].controls[control_index].error = error;
            return arr;

        case 'auto_test':
            result.forEach((i, infra_index)=>{
                i.result.forEach((ct, control_type_index)=>{
                    ct.controls.forEach((c, control_index)=>{
                        if(c.automated){
                            arr[infra_index].result[control_type_index].controls[control_index].test_result = c.test_result;
                        }
                    })
                })
            })
            return arr;

        default:
            throw new Error();
    }
}

export default function Assessment(props) {

    const [loading, setLoading] = useState(false);
    const [assessmentInfo, setAssessmentInfo] = useState({
        project_name : "",
        active_control_type : ""
    });
    const [maturity, setMaturity] = useState(0);
    const [assessment, dispatch] = useReducer(reducer, initialState);
    const [controls, setControls] = useState({
        infra_index : 0,
        control_type_index : 0
    });
    const [saving, setSaving] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const [auto_test_running, setAutoTestRunning] = useState(false);

    const history = useHistory();
    const { id } = useParams();

    
    useEffect(() => {
        setLoading(true);
        if(id)
            buildReq(`/assessment/${id}`)
                .then((res) => {
                    console.log(res.data);
                    dispatch({type : 'initialize', data : res.data.assessment});
                    setAssessmentInfo({
                        project_name : res.data.project_name
                    });
                    setMaturity(res.data.maturity)
                })
                .catch(checkResponse)
                .finally(()=>setLoading(false));
        else history.push('/home');
    }, [id, history]);

    useEffect(()=>{
        let scored_weight = 0, total_weight = 0;
        assessment.forEach(e=>e.result.forEach(ct=>{
            ct.controls.forEach(c=>{
                if(c.test_result === 'pass') scored_weight+=c.weight;
                if(c.test_result !== 'not applicable') total_weight+=c.weight;
            })
        }));
        console.log(scored_weight, total_weight);
        setMaturity(total_weight === 0 ? 0 : Math.floor((scored_weight/total_weight)*100));
    }, [assessment]);

    function runTest(infra_ind, ct_ind, c_ind){
        return (ev)=>{

            let control_inf = {
                infra_index : infra_ind,
                control_type_index : ct_ind,
                control_index : c_ind
            }

            if(assessment[infra_ind].result[ct_ind].controls[c_ind].connector.fields.some(e=>!e.cred && e.value.length === 0)){
                return toast.error("Please fill control data to start the Test");
            }

            dispatch({type : 'run_test', data : control_inf});
            buildReq(`/assessment/run_test`, 2, {
                assessment_id : id,
                infra_ind : infra_ind,
                control_type_ind: ct_ind,
                control_ind: c_ind,
                fields : assessment[infra_ind].result[ct_ind].controls[c_ind].connector.fields,
                comment : assessment[infra_ind].result[ct_ind].controls[c_ind].comment ? 
                    assessment[infra_ind].result[ct_ind].controls[c_ind].comment : ""
            })
                .then((res) => {
                    console.log(res.data);
                    dispatch({type : 'run_test_success', data : {
                        ...control_inf,
                        result : res.data
                    }});
                })
                .catch((err)=>{
                    if(err.response){
                        console.log(err.response)
                        if(err.response.status === 401){
                            let cookie = new Cookies();
                            cookie.remove("auth");
                            window.location.replace("/login");
                        }
                        dispatch({type : 'run_test_error', data : {
                            ...control_inf,
                            error : err.response.data
                        }});
                        toast.error(err.response.data);
                    }
                    else{
                        toast.error("Some server error occurred please try again");
                    }
                });
        }
    }

    function startAutoTest(){

        let timer=null;

        function checkAutoTest(){
            buildReq(`/assessment/check_automation_status/${id}`)
                .then((res) => {
                    if(!res.data.test_running){
                        setAutoTestRunning(false);
                        toast.success("Auto test completed successfully, please save your progress and reload the page to fetch results.", {
                            autoClose: 5000,
                            closeOnClick: true
                        });
                        clearInterval(timer);
                    }
                })
                .catch((err)=>{
                    checkResponse(err);
                    clearInterval(timer);
                });
        }

        buildReq(`/assessment/start_auto_assessment/${id}`)
            .then((res) => {
                toast.success("Auto test started successfully");
                setAutoTestRunning(true);
                timer = setInterval(checkAutoTest, 30000);
            })
            .catch(checkResponse);
    }

    function save(){
        setSaving(true);
        buildReq(`/assessment/${id}`, 3, {
            assessment : assessment,
        })
            .then((res) => {
                console.log(res.data);
                toast.success("Saved successfully");
            })
            .catch(checkResponse)
            .finally(()=>{
                setSaving(false);
            });
    }

    function submit(){

        if(assessment.some(e=>e.result.some(r=>r.controls.some(c=>!c.test_result)))){
            return toast.error(`Please answer all controls before submitting.`);
        }

        setSubmitting(true);
        buildReq(`/assessment/submit/${id}`, 3, {
            assessment : assessment,
        })
            .then((res) => {
                console.log(res.data);
                toast.success("Submitted successfully");
                history.push({pathname : `/report/${id}`})
            })
            .catch(checkResponse)
            .finally(()=>{
                setSubmitting(false);
            });
    }

    return (
        <Spin spinning={loading} wrapperClassName="w-100">
            <Layout>
                <Sider
                    style={{
                        overflow: 'auto',
                        height: '100vh',
                        position: 'fixed',
                        left: 0
                    }}
                >
                    <div className="logo">
                        {assessmentInfo.project_name}
                    </div>
                    <Menu theme="dark" mode="inline" defaultOpenKeys={['0']} defaultSelectedKeys={['0.0']}>
                        {assessment.map((e, i)=>
                            <Menu.SubMenu key={`${i}`} title={e.infrastructure_name} >
                                {
                                    e.result.map((ct, ind)=>
                                    <Menu.Item key={`${i}.${ind}`} icon={<span>{ct.percentage ? `${ct.percentage}% ` : "0% "}</span>}
                                        onClick={()=>setControls({ infra_index : i, control_type_index : ind})}
                                    >
                                        {ct.control_type}
                                    </Menu.Item>
                                    )
                                }
                            </Menu.SubMenu>
                        )}
                    </Menu>
                </Sider>
                <Layout className="site-layout" style={{ marginLeft: 200 }}>
                    <Header className="site-layout-background p-0 d-flex justify-content-around header">
                        
                        <h5 className="my-auto">
                            Project Maturity {maturity}%
                        </h5>
                        
                        <h5 className="my-auto">
                            {assessment[controls.infra_index]?.result[controls.control_type_index].control_type}  
                        </h5>

                        <div>
                            <Button type="primary" className="mx-2" loading={auto_test_running} onClick={startAutoTest}>
                                Start Automation Test
                            </Button>
                            <Button type="primary" className="mx-2" loading={saving} onClick={save}>
                                Save
                            </Button>
                            <Button type="primary" className="mx-2" loading={submitting} onClick={submit}>
                                Submit
                            </Button>
                        </div>

                    </Header>
                    <Content style={{ margin: '24px 16px 0', overflow: 'initial' }} className="assessment-content">
                        <div className="site-layout-background" style={{ padding: 24, textAlign: 'center' }}>
                            {
                                assessment[controls.infra_index]?.result[controls.control_type_index].controls.map((e, control_index)=>
                                    <Control control={e} key={control_index} ind={control_index} {...{
                                        onCommentChange : (ev)=>dispatch({type : 'comment', data : {
                                                ...controls,
                                                control_index , 
                                                value : ev.target.value
                                            }
                                        }),
                                        onControlStatusChange : (value)=>dispatch({type : 'change_control_status', data : {
                                                ...controls,
                                                control_index , 
                                                value
                                            }
                                        }),
                                        onFieldChange : (value, ind)=>dispatch({type : 'field', data : {
                                                ...controls,
                                                control_index , 
                                                field_index : ind,
                                                value : value
                                            }
                                        }), 
                                        runTest : runTest(controls.infra_index, controls.control_type_index, control_index)
                                    }} />
                                )
                            }
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </Spin>
    )

}