import React, { useState, useEffect, useRef } from 'react';
import { Button, Spin, Layout, Row, Col, Modal } from 'antd';
import "./style.css";
import { useHistory, useParams } from 'react-router-dom';
import buildReq, { checkResponse } from '../../request_builder';
import Control from './control';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import { Form } from 'react-bootstrap';

const { Header, Content } = Layout;

export default function Report(){

    const [loading, setLoading] = useState(false);
    const [assessmentInfo, setAssessmentInfo] = useState({
        project_name : "",
        maturity : 0,
        reviewer : "",
        taken_by : "",
        status : "",
        submittedAt : ""
    });
    const [assessment, setAssessment] = useState([]);
    const [reviewing, setReviewing] = useState(false);
    const [sending, setSending] = useState(false);
    const [email, setEmail] = useState('');
    const [visible, setVisible] = useState(false);
    
    const history = useHistory();
    const { id } = useParams();
    const name = useSelector(state => state?.auth?.name);
    const componentRef = useRef();

    useEffect(() => {
        setLoading(true);
        if(id)
            buildReq(`/assessment/${id}`)
                .then((res) => {
                    console.log(res.data);
                    setAssessment(res.data.assessment);
                    setAssessmentInfo({
                        maturity : res.data.maturity,
                        project_name : res.data.project_name,
                        taken_by : res.data.taken_by.name,
                        reviewer : res.data.reviewer?.name,
                        status : res.data.status,
                        submittedAt : res.data.submittedAt
                    });
                })
                .catch(checkResponse)
                .finally(()=>setLoading(false));
        else history.push('/home');
    }, [id, history]);

    function review(){
        setReviewing(true);
        buildReq(`/assessment/review/${id}`, 3)
            .then((res) => {
                console.log(res.data);
                toast.success("Report Updated successfully");
                setAssessmentInfo({...assessmentInfo ,reviewer : name, status : "REVIEWED"});
            })
            .catch(checkResponse)
            .finally(()=>{
                setReviewing(false);
            });
    }

    function sendEmail(){
        setSending(true);
        buildReq('/assessment/send_report', 2, { id, email })
            .then((res) => {
                toast.success("Email Sent Successfully");
                setVisible(false);
                setEmail("");
            })
            .catch(checkResponse)
            .finally(()=>{
                setSending(false);
            });
    }

    return(
        <Spin spinning={loading} wrapperClassName="container w-100">
            <Layout ref={componentRef}>

                <Header className="site-layout-background p-0 d-flex justify-content-around">
                    
                    <h5 className="my-auto text-white">
                        Project Maturity {assessmentInfo.maturity}%
                    </h5>
                    
                    <h5 className="my-auto text-white">
                        {assessmentInfo.project_name}  
                    </h5>

                    <div className="invisible-on-print">
                        <ReactToPrint
                            trigger={() => <Button type="primary" className="mx-2">Print</Button>}
                            content={() => componentRef.current}
                        />
                        
                        {
                            assessmentInfo.status !== 'REVIEWED' && 
                            <Button type="primary" className="mx-2" loading={reviewing} onClick={review}>
                                Mark As Reviewed
                            </Button>
                        }    
                        
                        <Button type="primary" className="mx-2" loading={sending} onClick={()=>setVisible(true)}>
                            Email PDF
                        </Button>     

                    </div>

                </Header>
                
                <Content style={{ overflow: 'initial' }}>
                    <div className="site-layout-background" style={{ padding: 24 }}>

                        <Row>
                            <Col span={3}><strong>Taken By</strong></Col>
                            <Col span={6}>{assessmentInfo.taken_by}</Col>
                        </Row>

                        <Row>
                            <Col span={3}><strong>Reviewed By</strong></Col>
                            <Col span={6}>{assessmentInfo.reviewer}</Col>
                        </Row>

                        <Row>
                            <Col span={3}><strong>Submitted On</strong></Col>
                            <Col span={6}>
                                {assessmentInfo.submittedAt ? moment(assessmentInfo.submittedAt).format("MMMM Do YYYY, h:mm a") : ""}
                            </Col>
                        </Row>

                        {
                            assessment.map((e, i)=> (
                                <React.Fragment key={i}>
                                    <h3 className="text-center mt-3">{e.infrastructure_name}</h3>
                                    {
                                        e.result.map((ct, j)=>(
                                            <div key={j}>
                                                <h5 className="mt-5">{ct.control_type}</h5>
                                                {
                                                    ct.controls.map((e, k)=>(
                                                        <Control control={e} key={k} ind={k} />
                                                    ))
                                                }
                                            </div>
                                        ))
                                    }
                                </React.Fragment>
                            ))
                        }
                    </div>
                </Content>

            </Layout>
            
            <Modal 
                title="Enter email to send report" 
                visible={visible} 
                onOk={sendEmail} onCancel={()=>setVisible(false)} 
                okButtonProps={{loading: sending}}
                cancelButtonProps={{loading: sending}}
            >
                <Form className="p-2 flex-fill">
                    <Form.Group>
                        <Form.Label>
                            <h6 className="mb-0">Email Address</h6>
                        </Form.Label>
                        <Form.Control as="input" type="email" 
                            placeholder="Enter Email Address" value={email} 
                            onChange={(e)=> setEmail(e.target.value)} 
                        />
                    </Form.Group>
                </Form>
            </Modal>

        </Spin>
    )

}