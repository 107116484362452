import React from 'react';
import { Button } from 'antd';
import { Form } from 'react-bootstrap';
import "./style.css"
import { AiOutlinePlayCircle } from 'react-icons/ai';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';

export default function Control({
    control : {
        test_automated,
        weight,
        result,
        control,
        comment,
        test_result,
        connector,
        failed_for,
        error,
        loading 
    },
    auto_test_running,
    onCommentChange,
    onControlStatusChange,
    onFieldChange, 
    runTest,
    ind
}) {

    return (
        <div className="my-4 text-left d-table w-100" style={{backgroundColor: test_automated ? 'transparent' : '#f7fbff'}}>
            <div className="control-status d-table-cell border-blue rounded-left overflow-auto">
                <div className="p-2 flex-fill">
                    <h6 className="mb-0">Automation Status:</h6>
                    {
                        test_automated ?
                        <p className="text-primary">Automated</p>
                        :
                        "Manual"
                    }
                </div>
                <div className="p-2 flex-fill">
                    <h6 className="mb-0">Control Severity:</h6>
                    {
                        weight === 1 ?
                        <strong className="text-success">Low</strong>
                        :
                        weight === 2 ?
                        <strong className="text-warning">Medium</strong>
                        :
                        <strong className="text-danger">High</strong>
                    }
                </div>
                {failed_for && failed_for.length > 0 &&
                    <div className="p-2 flex-fill">
                        <h6 className="mb-0">Failed For:</h6>
                        <ul>
                        {failed_for.map((e, i)=><li key={i}>{e}</li>)}
                        </ul>
                    </div>
                }

                {error &&
                    <div className="p-2 flex-fill">
                        <h6 className="mb-0">Error</h6>
                        <p className="text-danger">{error}</p>
                    </div>
                }
            </div>
            <div className="control-body d-table-cell border-top-blue border-bottom-blue">

                <div className="p-2 border-bottom-blue flex-fill">
                    <h6 className="mb-0">Control {ind + 1}</h6>
                    <div style={{wordBreak : 'keep-all'}}>
                        {control}
                    </div>
                </div>
                
                {
                    test_automated && connector && connector.api && connector.fields?.length > 0 &&
                    <div className="p-2 border-bottom-blue flex-fill">
                        <h6>Connector Data</h6>
                        <Form>
                            {
                                connector.fields.map((e, i)=>
                                    !e.cred && ( 
                                        e.multiple ?
                                        <React.Fragment>
                                            <TagsInput inputProps={{placeholder : e.placeholder}} 
                                                onlyUnique={true} disabled={test_result === 'not applicable'}
                                                onChange={(val)=>onFieldChange(val, i)} value={e.value}
                                            />
                                            <span className="text-primary multiple-info">
                                                Press tab or enter to add multiple
                                            </span>
                                        </React.Fragment>
                                        :
                                        <Form.Group key={i}>
                                            <Form.Control type="text" placeholder={e.placeholder}
                                                disabled={test_result === 'not applicable'}
                                                value={e.value && e.value.length > 0 ? e.value[0] : ""} 
                                                onChange={(ev)=>onFieldChange(ev.target.value, i)}/>
                                        </Form.Group>
                                    )
                                )
                            }
                        </Form>
                    </div>
                }

                <Form className="p-2 flex-fill">
                    <Form.Group>
                        <Form.Label><h6 className="mb-0">Comment</h6></Form.Label>
                        <Form.Control 
                            as="textarea" rows="2" type="textarea" 
                            placeholder="Enter Your Comment" value={comment ? comment : ""} 
                            onChange={onCommentChange} 
                        />
                    </Form.Group>
                </Form>
            </div>
            <div className="control-action d-table-cell p-2 border-blue rounded-right">
                <div className="d-flex flex-column w-100">
                    <Button 
                        type={ test_result === "pass" ? "primary" : "default"} 
                        className={`my-2 ${test_result === "pass" ? 'bg-success text-white' : ""}`}
                        disabled={test_automated && test_result !== "pass"} 
                        onClick={()=>onControlStatusChange('pass')}
                    >
                        Pass
                    </Button>
                    <Button 
                        type={ test_result === "fail" ? "primary" : "default"} 
                        className={`my-2 ${test_result === "fail" ? 'bg-danger text-white' : ""}`} 
                        disabled={test_automated && test_result !== "fail"} 
                        onClick={()=>onControlStatusChange('fail')}
                    >
                        Fail
                    </Button>
                    <Button 
                        type={ test_result === "not applicable" ? "primary" : "default"} 
                        className="my-2" 
                        onClick={()=>onControlStatusChange(test_result === "not applicable" ? "" : 'not applicable')}
                    >
                        Not Applicable
                    </Button>
                    {
                        test_automated &&
                        <Button 
                            className="my-2" onClick={runTest}
                            icon={<AiOutlinePlayCircle size={20} className="mr-2" />} 
                            disabled={ auto_test_running || test_result === "not applicable"}
                            loading={loading}
                        >
                            Run Test
                        </Button>
                    }
                </div>
            </div>
        </div>
    )

}