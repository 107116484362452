import React, { useEffect } from 'react';
import "./style.css"
import buildReq from '../../request_builder';
import { useHistory, useParams } from 'react-router-dom';
import ReactLoading from 'react-loading';


export default function AutoTest(props) {

    const history = useHistory();
    const { id } = useParams();

    useEffect(() => {
        function startAutoTest(){

            let timer=null;
    
            function checkAutoTest(){
                buildReq(`/assessment/check_automation_status/${id}`)
                    .then((res) => {
                        if(!res.data.test_running){
                            clearInterval(timer);
                            history.push({pathname : `/assessment/${id}`});
                        }
                    })
                    .catch((err)=>{
                        clearInterval(timer);
                        history.push({pathname : `/assessment/${id}`});
                    });
            }
    
            buildReq(`/assessment/start_auto_assessment/${id}`)
                .then((res) => {
                    timer = setInterval(checkAutoTest, 30000);
                })
                .catch(err => {
                    history.push({pathname : `/assessment/${id}`});
                });
        }

        startAutoTest();

    }, [history, id]);

    return (
        <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
            <img src={require('../../images/logo.jpg')} alt="CyberRMP" height="200px" />
            <h5 className="mt-4">Auto test is running. You will be redirect to assessment page once its completed.</h5>
            <div className="w-100 d-flex justify-content-center align-items-center">
                <ReactLoading type='bubbles' color='#1890ff' height='40%' width='100px'/>
            </div>
        </div>
    )

}