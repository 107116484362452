import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify'; 
import { useCookies } from 'react-cookie';

import {  domain } from '../../const';
import { loginAction } from '../../actions/auth';
import buildReq from '../../request_builder';

export default function Login({login, identify, ...props}){

    const [id, setId] = useState("");
    const [pass, setPass] = useState("");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    let history = useHistory();
    let cookie = useCookies(['auth']) 

    const onClick = async (event)=>{
        event.preventDefault();
        if(id.length === 0 || pass.length === 0){
            toast.error("Please enter User ID and password")
            return;
        }
        try{
            setLoading(true)
            let res = await buildReq('/users/login', 2, {user_id : id, password : pass})
            res = res.data;
            setLoading(false);
            cookie[1]('auth',JSON.stringify({...res, user_id : id}),{
                domain : domain,
                path : "/",
                sameSite : true,
                maxAge: 3600
            });
            dispatch(loginAction("LOGIN", {res, user_id : id}));  
            toast.success("Signed in successfully");
            history.push({pathname : "/home"});
        }
        catch(err){
            setLoading(false)
            console.log(err);
            if(err.response){
                console.log(err.response)
                toast.error(err.response.data);
            }
            else toast.error("Some error occurred, please try again")
        }
    }

    return(
        <div className="h-100 w-100 container d-flex align-items-center">
            <div className="row my-auto w-100">
                <div  className="col-12 col-md-8 offset-md-2">
                    <Card>
                        <Card.Header>
                            <h5 className="h-5 mb-0">Sign In</h5>
                        </Card.Header>
                        <Card.Body>

                            <Form className="mb-3" onSubmit={onClick}>

                                <Form.Group controlId="email">
                                    <Form.Label>User ID</Form.Label>
                                    <Form.Control type="text" placeholder="Enter User ID" onChange={(ev)=>setId(ev.target.value)}/>
                                </Form.Group>

                                <Form.Group controlId="password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" placeholder="Password" onChange={(ev)=>setPass(ev.target.value)}/>
                                </Form.Group>

                                <Button type="primary" htmlType="submit"  onClick={onClick} loading={loading}> 
                                    Sign In
                                </Button>

                            </Form>   
                            
                            <Link className="text-decoration-none d-block" to="/forgot_pass">Forgot Password ?</Link>

                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    )

}