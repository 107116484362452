import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { domain } from '../../const';
import { FaBars, FaTimes } from 'react-icons/fa'
import buildReq from '../../request_builder';
import { Dropdown, Menu, Avatar, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import "./style.css"

const Nav = ({Logout, authenticated, auth})=>{

    return (

        <ul>
            {
                authenticated ? 
                <React.Fragment>
                    <li>
                        <Link to="/home">
                            Dashboard
                        </Link>
                    </li>
                    <li>
                        <Dropdown overlay={menu({Logout})} className="ml-4">
                            <button className="btn btn-link ant-dropdown-link" onClick={e => e.preventDefault()}>
                            <Avatar icon={<UserOutlined style={{fontSize : 20}}/>} shape="circle" size={30} className="mr-2"/> 
                            {/* <span className="align-middle">{auth.name}</span>  */}
                            </button>
                        </Dropdown>
                        <span>{auth.name}</span>
                    </li>
                </React.Fragment>
                :
                <li>
                    <Link to="/login">Sign In</Link>
                </li>
            }
        </ul>
    )
}

const menu = ({Logout})=>(
    <Menu>
        <Menu.Item>
            <Link to="/profile" className="text-decoration-none text-reset">My Profile</Link>
        </Menu.Item>
        <Menu.Item>
            <Button type="link" htmlType="button" onClick={()=>Logout()} 
                className="text-decoration-none text-reset p-0">
                Logout
            </Button>
        </Menu.Item>
    </Menu>
);

function Header2({ active, setActive, toggle, setToggle }){

    const cookie = useCookies();
    const auth = useSelector(state => state.auth);
    const [authenticated, setAuthenticated] = useState(auth?.token);

    let history = useHistory();
    let location = useLocation();
    
    async function Logout(){    
        cookie[2]("auth",{
            domain : domain
        });
        await buildReq('/users/logout');
        let { from } = location.state || { from: { pathname: "/login" } };
        history.replace(from);
    }

    useEffect(()=>{
        setAuthenticated(auth?.token);
    },[auth, setAuthenticated]);

    return(
        <header id="header" className="fixed-top">
            <div className="container h-100">

                <div className="logo float-left m-0 h-100">
                    <Link to="/home" className="scrollto h4 font-weight-bolder text-decoration-none">
                        <img src="/logo.jpg" alt="" className="img-fluid mr-2 h-100"/>
                    </Link>
                </div>

                <nav className="main-nav float-right d-none d-lg-block">
                    <Nav {...{setActive, Logout, authenticated, active, setToggle, auth}}/>
                </nav>

                <nav className="mobile-nav d-lg-none">
                    <Nav {...{setActive, Logout, authenticated, active, setToggle, auth}}/>
                </nav>
            
                <div className="mobile-nav-overly" style={{display : toggle ? "block" : "none"}}></div>

                <button type="button" className="mobile-nav-toggle d-lg-none">
                    {
                        toggle ?
                        <FaTimes onClick={()=>setToggle(false)}/> : <FaBars onClick={()=>setToggle(true)}/>
                    }
                </button>
            
            </div>
        </header>
    )
}

const mapStateToProps = (st)=>({auth : st.auth})
export default connect(mapStateToProps, null)(Header2);