import React, { useState, useEffect } from 'react';
import { Button, Table, Spin } from 'antd';
import "./style.css"
import buildReq, { checkResponse } from '../../request_builder';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

export default function AssessmentTable({status}) {

    const [loading, setLoading] = useState(false);
    const [assessments, setAssessments] = useState([]);
    const history = useHistory();
    const columns = [
        {
            title: 'Project Name',
            dataIndex: 'project_name',
            key: 'project_name',
        },
        {
            title: 'Taken By',
            key: 'name',
            render: (text, record)=>record.taken_by.name
        },
        {
            title: 'Reviewed By',
            key: 'name',
            render: (text, record)=>record?.reviewer?.name
        },
        {
            title: 'Project Maturity',
            dataIndex: 'maturity',
            key: 'maturity',
            width : "10%"
        },
        {
            title: 'Start Date',
            render: (text, record)=>moment(record.createdAt).format("MMMM Do YYYY, h:mm a"),
            key: 'created_at',
            width : "19%"
        },{
            title: 'Submit Date',
            render: (text, record)=>record.submittedAt && moment(record.submittedAt).format("MMMM Do YYYY, h:mm a"),
            key: 'submitted_at',
            width : "19%"
        },
        {
            title : "Action",
            render : (record)=>(
                <div className="d-flex flex-wrap">
                    <Button className="m-2" onClick={()=>{
                        switch(status){
                            case "ONPROGRESS":
                                history.push({pathname : `/assessment/${record._id}`});
                                break;

                            case "SUBMITTED":
                                history.push({pathname : `/report/${record._id}`});
                                break;

                            case "REVIEWED":
                                history.push({pathname : `/report/${record._id}`});
                                break;

                            default:
                                break; 
                        }
                    }}>
                        {
                            status === 'ONPROGRESS' ?
                            "Continue"
                            :
                            status === 'SUBMITTED' ?
                            "Review"
                            :
                            "View Report"
                        }
                    </Button>
                    {
                        status === 'ONPROGRESS' &&
                        <Button className="m-2" onClick={()=>history.push(`/edit/${record._id}`)}>
                            Edit
                        </Button>   
                    }
                </div>
            ) 
        }
    ];

    useEffect(() => {
        setLoading(true);
        buildReq(`/assessment?status=${status}`)
            .then((res) => {
                setLoading(false);
                console.log(res.data);
                setAssessments(res.data.map((e, i)=>({...e, key : i})));
            })
            .catch(checkResponse)
    }, [status])

    return (
        <Spin spinning={loading}>
            <Table dataSource={assessments} columns={columns} />
        </Spin>
    )

}