import React from 'react';
import "./style.css"

export default function Control({
    control : {
        test_automated,
        weight,
        control,
        comment,
        test_result,
        connector,
        failed_for,
        error,
    },
    ind
}) {

    return (
        <div className="mt-4 text-left d-table w-100 no-preview-break">
            <div className="control-status border-blue d-table-cell rounded-left no-preview-break">
                <div className="p-2">
                    <h6 className="mb-0">Automation Status:</h6>
                    {
                        test_automated ?
                        "Automated"
                        :
                        "Manual"
                    }
                </div>
                <div className="p-2">
                    <h6 className="mb-0">Control Severity:</h6>
                    {
                        weight === 1 ?
                        <strong className="text-success">Low</strong>
                        :
                        weight === 2 ?
                        <strong className="text-warning">Medium</strong>
                        :
                        <strong className="text-danger">High</strong>
                    }
                </div>
                {
                    failed_for.length > 0 &&
                    <div className="p-2">
                        <h6 className="mb-0">Failed For:</h6>
                        <ul>
                        {failed_for.map((e, i)=><li key={i}>{e}</li>)}
                        </ul>
                    </div>
                }

                {
                    error &&
                    <div className="p-2">
                        <h6 className="mb-0">Error</h6>
                        <p className="text-danger">{error}</p>
                    </div>
                }
            </div>
            
            <div className="control-body border-top-blue border-bottom-blue d-table-cell no-preview-break">

                <div className="p-2">
                    <h6 className="mb-0">Control {ind + 1}</h6>
                    <div>
                        {control}
                    </div>
                </div>
                
                {
                    test_automated && connector && connector.api && connector.fields?.length > 0 &&
                    <div className="p-2">
                        <h6>Connector Data</h6>
                        <div>
                            {
                                connector.fields.map((e, i)=>
                                    !e.cred && ( 
                                        <React.Fragment key={i}>
                                            <strong>{e.placeholder}</strong>
                                            <ul>
                                                {
                                                    e.value.map((v,i)=>
                                                        <li key={i}>{v}</li>
                                                    )
                                                }
                                            </ul>
                                        </React.Fragment>
                                    )
                                )
                            }
                        </div>
                    </div>
                }

                {
                    comment &&
                    <div className="p-2">
                        <h6 className="mb-0">Comment</h6>
                        <p>{comment}</p>
                    </div>
                }

            </div>

            <div className="control-action p-2 border-blue d-table-cell rounded-right no-preview-break">
                <h6 className="mb-0">Result</h6>
                <p>{test_result}</p>
            </div>
        </div>
    )

}