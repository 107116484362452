import axios from 'axios';
import { host } from './const';
import { toast } from 'react-toastify'
import { Cookies } from "react-cookie";

export default function buildReq (url, method = 1, body=null){
    
    let obj = {};
    switch(method){
        case 1:
            obj['method'] = "GET"
            break;

        case 2:
            obj['method'] = "POST"
            if(body){
                obj['data'] = JSON.stringify(body);
                obj['headers'] = {
                    ...obj['headers'],
                    "Content-Type" : "application/json"
                }
            }
            break;

        case 3:
            obj['method'] = "PATCH"
            if(body){
                obj['data'] = JSON.stringify(body);
                obj['headers'] = {
                    ...obj['headers'],
                    "content-type" : "application/json"
                }
            }
            break;

        default: 
            return false;
    }

    return axios(host+url,{...obj, withCredentials : true});
}

export const checkResponse = (err)=>{
    
    if(err.response){
        console.log(err.response)
        if(err.response.status === 401){
            let cookie = new Cookies();
            cookie.remove("auth");
            window.location.replace("/login");
        }
        toast.error(err.response.data);
    }
    else{
        toast.error("Some server error occurred please try again");
    }
}