export const authReducer = (state={token : ""}, action)=>{
    let obj = Object.assign({}, state);;
    switch (action.type){
        case "UPDATE_USER" :
            obj = { ...obj, ...action.payload };
            return obj;
        case "LOGIN" :
            obj.token   = action.payload.token;
            obj.name    = action.payload.name;
            return obj;
        case "LOGOUT" :
            return {
                token : "",
                name : ""
            };
        default : 
            return state;
    }
}